.navbar {
    width: 100%;
    height: 100vh;
    background: #046582;
    box-sizing: border-box;
    box-shadow: 1px 0 10px black;
    position: fixed;
    top: 0;
    z-index: 2;
    overflow: scroll;
}
.navbarOne {
    width: 400px;
    height: 100vh;
    background: #93329E;
    box-sizing: border-box;
    position: fixed;
    left: -450px;
}
.navbar h2 {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    text-align: right;
    background: #440A67;
    position: sticky;
    top: 0;
    padding: 20px;
    color: white;
    box-sizing: border-box;
    box-shadow: 0 0 10px black;
    z-index: 2;
    overflow: scroll;
}
.navbar h2 span:nth-child(2) {
    font-size: .8rem;
    color: rgb(0, 247, 214);
}
.navbar h3 {
    text-align: center;
    padding: 15px;
    color: white;
}
.users {
    list-style: none;
    padding: 0 20px;
}
.users li {
    width: 100%;
    height: 45px;
    margin-top: 7px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    position: none;
    align-items: center;
    color: white;
    border-radius: 10px;
    box-shadow: 0 3px 5px white;
    box-sizing: border-box;
}
.exit {
    position: relative;
    left: 50%;
    bottom: 20px;
    margin-top: 50px;
    transform: translateX(-50%);
    padding: 15px 30px;
    border: none;
    border-radius: 10px;
    outline: none;
    background: red;
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    box-shadow: 0 0 10px black;
    cursor: pointer;
}
.exit:hover {
    box-shadow: inset 0 0 10px black;
}
.btnOne {
    position: fixed;
    top: 10px;
    left: 20px;
    height: 50px;
    width: 50px;
    z-index: 10;
    color: white;
    font-size: 2.5rem;
    font-weight: 400;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 10px black;
    outline: none;
    background: transparent;
    cursor: pointer;
}
.btnOne:hover {
    box-shadow: 0 0 5px black;
}
.texts {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    width: 100vw;
    z-index: 1;
}
.text {
    height: 50px;
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    border: 2px solid white;
    outline: none;
    box-sizing: border-box;
}
.post {
    height: 50px;
    width: 50px;
    padding: 5px;
    border: 1px solid teal;
    outline: none;
    background: teal;
    color: white;
}
.post:disabled{
    background: grey;
}
.chats {
    padding-top: 60px;
    padding-bottom: 110px;
}
.user {
    color: blanchedalmond;
}
.styleOne {
    width: fit-content;
    background : #3a6351;
    display : flex;
    text-align : right;
    flex-direction : column;
    padding : 5px 15px;
    max-width : 80vw;
    border-radius : 10px;
}
.styleTwo {
    width: fit-content;
    background : #00BCB4;
    display : flex;
    text-align : right;
    flex-direction : column;
    padding : 5px 15px;
    max-width : 80vw;
    border-radius : 10px;
}
.styleThree {
    display : flex;
    flex-direction : column;
    padding : 1px 10px;
    width : 100%;
    box-sizing : border-box;
    margin-top : 5px;
    color : white;
    align-items : flex-start;
    cursor: pointer;
}
.styleFour {
    display : flex;
    flex-direction : column;
    padding : 1px 10px;
    width : 100%;
    box-sizing : border-box;
    margin-top : 5px;
    color : white;
    align-items : flex-end;
    cursor: pointer;
}
.delbtn {
    border: none;
    outline: none;
    background: transparent;
    margin-left: 5px;
    position: relative;
    top: 3px;
}