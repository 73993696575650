.login {
    position: relative;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    justify-content: space-around;
}

.create {
    height: 350px;
    width: 300px;
    text-align: center;
    color: white;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.61), rgba(255, 255, 255, 0.26));
    box-shadow: 3px 7px 10px black;
}
.create h3 {
    margin-top: 70px;
}
.logo {
    padding: 5px;
    height: 50px;
    width: 50px;
    border: none;
    margin: 30px 10px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    background: white;
}