* {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  display: none;
}
.container {
  margin-left: 3vw;
  margin-right: 3vw;
}

.App {
  background: #440A67;
  height: 100vh;
  overflow: scroll;
  position: fixed;
  width: 100vw;
}

@media (max-width:760px) {
  .login {
      display: flex;
      flex-direction: column;
  }
  .create {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
  }
}
